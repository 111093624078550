import * as amplitude from '@amplitude/analytics-browser';
import { Box, useMediaQuery } from '@material-ui/core';
import {
    Theme,
    createStyles,
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import { Period } from '@spike/model';
import { AMPLITUDE } from 'constants/index';
import { Moment } from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import DateSelector from './DateSelector/DateSelector';
import WeekSelector from './DateSelector/WeekSelector';
import StaffSelector from './StaffSelector';
import Button from 'components/UI/V2/Button/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import CalendarSelector from './CalendarSelector';
import { daily, monthly, weekly } from './CalendarSelector/CalendarSelector';
import MonthSelector from './DateSelector/MonthSelector';

interface CalendarHeaderProps {
    week: Period;
    date: Moment;
    month: Moment;
    selectedStaffIds: Array<number> | undefined;
    calendarType: string;
    onChangeMonth: (date: Moment) => void;
    onChangeWeek: (week: Period) => void;
    onChangeDate: (date: Moment) => void;
    onSelectStaffIds?: (selectedStaffIds: Array<number> | undefined) => void;
    onChangeView?: (view: string) => void;
    onAdd?: () => void;
    onToday: () => void;
}

const breakpoint = 1200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(breakpoint)]: {
                flexDirection: 'column',
                marginTop: '10px'
            },
            [theme.breakpoints.up(breakpoint)]: {
                marginBottom: '10px',
                marginTop: '10px'
            }
        },
        leftContainer: {
            display: 'flex',
            [theme.breakpoints.only('xs')]: {
                paddingLeft: '10px',
                paddingRight: '10px'
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: '25px',
                paddingRight: '20px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '25px',
                paddingRight: '25px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '25px',
                paddingRight: '25px'
            }
        },
        middleContainer: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center'
        },
        rightContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '25px'
        },
        topContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        },
        bottomContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '7px',
            marginBottom: '10px'
        },
        viewSelector: {
            '& > div > div': {
                height: '29px'
            },
            [theme.breakpoints.up(breakpoint)]: {
                marginLeft: '20px'
            }
        },
        addButton: {
            [theme.breakpoints.down(breakpoint)]: {
                height: '30px'
            },
            [theme.breakpoints.up(breakpoint)]: {
                height: '38px'
            }
        },
        todayContainerButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        todayButton: {
            color: '#000',
            backgroundColor: '#fff',
            border: '1px solid #000',
            [theme.breakpoints.down(breakpoint)]: {
                height: '30px'
            },
            [theme.breakpoints.up(breakpoint)]: {
                height: '38px'
            },
            '&:hover': {
                backgroundColor: '#fff'
            }
        }
    })
);

export const CalendarHeader: FunctionComponent<CalendarHeaderProps> = props => {
    const classes = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down(breakpoint));

    const changeViewHandler = (viewId: string) => {
        props.onChangeView && props.onChangeView(viewId);
        viewId === weekly.id
            ? amplitude.track(AMPLITUDE.CTA_WEEKLY_CALENDAR)
            : amplitude.track(AMPLITUDE.CTA_WEEKLY_CALENDAR);
    };

    const staffSelector = (
        <StaffSelector
            selectedIds={props.selectedStaffIds}
            onSelect={props.onSelectStaffIds}
        />
    );

    const buttonToday = (
        <Box className={classes.todayContainerButton}>
            <Button
                size="medium"
                label='Today'
                onClick={props.onToday}
                className={classes.todayButton}
            />
        </Box>
    );

    const calendarSelector = (
        <CalendarSelector
            selectedId={props.calendarType}
            onSelect={changeViewHandler}
        />
    );

    const addButton = (
        <Button
            size="medium"
            leftIcon={faPlus}
            label={isSmall ? 'Add' : 'Add Appointment'}
            onClick={props.onAdd}
            className={classes.addButton}
        />
    );

    const weekSelector = (
        <WeekSelector week={props.week} onChange={props.onChangeWeek} />
    );

    const dateSelector = (
        <DateSelector date={props.date} onChange={props.onChangeDate} />
    );

    const monthSelector = (
        <MonthSelector date={props.month} onChange={props.onChangeMonth} />
    );

    return (
        <Box className={classes.container}>
            {isMedium ? (
                <>
                    <Box className={classes.topContainer}>
                        <Box className={classes.leftContainer}>
                            {props.onAdd && addButton}
                        </Box>
                        <Box className={classes.rightContainer}>
                            {staffSelector}
                            {calendarSelector}
                        </Box>
                    </Box>
                    <Box className={classes.bottomContainer}>
                        {buttonToday}
                        {props.calendarType === weekly.id && weekSelector}
                        {props.calendarType === daily.id && dateSelector}
                        {props.calendarType === monthly.id && monthSelector}
                    </Box>
                </>
            ) : (
                <>
                    <Box className={classes.leftContainer}>
                        {props.onAdd && addButton}
                    </Box>
                    <Box className={classes.middleContainer}>
                        {buttonToday}
                        {props.calendarType === weekly.id && weekSelector}
                        {props.calendarType === daily.id && dateSelector}
                        {props.calendarType === monthly.id && monthSelector}
                    </Box>
                    <Box className={classes.rightContainer}>
                        {staffSelector}
                        {calendarSelector}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default CalendarHeader;
